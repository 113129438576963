import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import CardHeader from '@material-ui/core/CardHeader';

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import AccountCircle from "@material-ui/icons/Face";
import WarningIcon from "@material-ui/icons/Warning";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import axios from "axios";
import envConfig from "../../../../bin/env.config";
import store from "../../../../store/store";

import { Helpers } from "../../../../utility/helpers";
import { useSelector } from "react-redux";

import ProductAttributesInfo from "../../../Product/ProductAttributesInfo";
const useStyles = makeStyles((theme) => ({
  media: {
    //  object-fit is not supported by IE11.
    objectFit: "contain",
    cursor: "pointer",
    width: 'auto',
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "10px",
    "&:hover": {
      opacity: "0.7",
    },
    [theme.breakpoints.down("md")]: {
      // width: '100px',
      // height: '100px',
    },
  },
  card: {
    margin: "10px",
    paddingBottom: "10px",
    position: "relative",
  },
  cardContent: {
    height: "200px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      boxSizing: "border-box",
    },
    overflow: "hidden",
  },
  centeredCardConent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  notMatchButton: {
    color: "red",
    borderColor: "red",
  },
  // removeAcceptedButton: {
  //   color: "blue",
  //   borderColor: "blue",
  // },
  original: {
    backgroundColor: theme.palette.secondary.main,
    position: "-webkit-sticky",
    position: "sticky",
    top: 64,
  },
  originalCard: {},
  matched: {
    // backgroundColor: theme.palette.primary.main,
  },
  matchedCard: {
    border: `2px solid`,
    borderColor: theme.palette.primary.main,
  },
  potential: {},
  potentialCard: {},
  container: {
    paddingTop: "8px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    justifySelf: "start",
    backgroundColor: "#DDD",
  },
  item: {
    padding: "4px",
  },
  companyName: {
    fontWeight: "bold",
  },
  tableTd: {
    padding: "0px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  lockedByYou: {
    backgroundColor: theme.palette.primary.main,
  },
  lockedByOther: {
    backgroundColor: "red",
    color: "#FFF",
  },
  wrongListLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginLeft: "auto",
    fontSize: "0.8125rem",
  },
  moreButtons:{
    minWidth:"40px"
  }
}));

export default function ReviewItem(props) {
  const history = useHistory();
  const classes = useStyles();

  const {
    productMatch,
    showType,
    groupId,
    locked,
    handleNotAMatch,
    handleWrong,
    handleMatches,
    simSource,
    openMoreButtonsMenu
  } = props;
  const companies = useSelector(state => state.companies.companies);
  //showType: 'original'||'potential'||'ingroup'
  // console.log(productMatch);
  //
  let productImageUrl = productMatch.image_url;

  const handleWrongLocal = () => {   
    handleWrong && handleWrong(productMatch);
  };

  const handleNotAMatchLocal = () => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/wrongs`;
    const postData = {
      product_matched_ids: [productMatch.id],
      product_id: groupId,
      simSource: simSource,
    };
    axios
      .post(url, postData, {
        headers: {
          Authorization: JSON.stringify(store.getState().session.token),
        },
      })
      .then((response) => {
        handleNotAMatch && handleNotAMatch(productMatch);      
      })
      .catch((err) => {
        console.log(err);
      });
    //  onRemove&&onRemove(productMatch.id);
  };
  const handleMatchesLocal = () => {
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/pbs/matches`;
    if (groupId && productMatch && productMatch.id > 0) {
      const postData = {
        product_matched_id: productMatch.id,
        product_id: groupId,
        simSource: simSource,
      };
      console.log(postData);
      axios
        .post(url, postData, {
          headers: {
            Authorization: JSON.stringify(store.getState().session.token),
          },
        })
        .then((response) => {
         
          handleMatches && handleMatches(productMatch); //recheck this ---> it should be handleMatches
        })
        .catch((err) => {
          console.log(err);
        });
      //  onRemove&&onRemove(productMatch.id);
    }
  };
  const handleMoreButtonsClick=(e)=>{
    openMoreButtonsMenu && openMoreButtonsMenu(e, productMatch)
  }
  const companyLogo = (companyName)=>{
    // console.log(companyName)
    // console.log(companies)
    const company = companies.find(x => x.name === companyName)

    return company?company.companyLogoLink:Helpers.companyLogo(companyName)
  }
  return (
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        className={
          showType === "original"
            ? classes.original
            : productMatch.type === "matched"
            ? classes.matched
            : classes.potential
        }
      >
        <Card
          className={`${classes.card} ${
            showType === "original"
              ? classes.originalCard
              : productMatch.type === "matched"
              ? classes.matchedCard
              : classes.potentialCard
          }`}
        >
          <CardHeader
            title={
              <img
                alt={productMatch.company_name}
                style={{ height: "50px" }}
                src={companyLogo(productMatch.company_name)}
              />
            }
          />
          <CardMedia
            className={classes.media}
            component="img"
            image={productImageUrl}
            title={productMatch.name}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "/resources/images/no-picture-available.png";
            }}
          />
          <CardContent className={classes.cardContent}>
            <Typography className={classes.productName} variant="body2">
              {Helpers.titleCase(productMatch.fullname)}
            </Typography>
            <Typography className={classes.companyName} variant="body2">
              {/* {productMatch.company_name} */}
              {productMatch.size} #{productMatch.id}
            </Typography>
              <ProductAttributesInfo productId={productMatch.id}/>
            <Typography className={classes.productName} variant="body2">
               <b>Code:</b>{productMatch.original_code}
            </Typography>

            {productMatch.sim_name && (
              <Typography variant="body2">
                <strong>sim:</strong>
                {productMatch.sim_name}
                {productMatch.product_matching_group_id && (
                  <Chip
                    variant="outlined"
                    size="small"
                    icon={<WarningIcon />}
                    label={`Belonged to #${productMatch.product_matching_group_id}`}
                    color="secondary"
                    component="a"
                    href={`/matching-group/${productMatch.product_matching_group_id}`}
                    clickable
                  />
                )}
              </Typography>
            )}
            {productMatch.type === "variety" && (
              <Typography variant="body2">
                  <Chip
                    size="small"
                    label={`Multi-variety`}
                  />                
              </Typography>
            )}
            <Table className={classes.table}>
              <TableBody>
                {productMatch.storeProductPrices &&
                  productMatch.storeProductPrices.map(
                    (storeProductPrice, i) => (
                      <TableRow key={i}>
                        <TableCell className={classes.tableTd}>
                          {storeProductPrice.store_name}
                        </TableCell>
                        <TableCell className={classes.tableTd}>
                          ${storeProductPrice.current_price_ea}
                          {/* /Reg:${storeProductPrice.regular_price_ea} */}
                        </TableCell>
                        <TableCell className={classes.tableTd}>
                          {storeProductPrice.sold_by_type_name}
                        </TableCell>
                        <TableCell className={classes.tableTd}>
                          {storeProductPrice.sale_description}
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </CardContent>
          <CardActions>
            {productMatch.type === "potential" && (
              <>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.notMatchButton}
                  onClick={handleWrongLocal}
                  disabled={groupId == productMatch.id}
                >
                  Wrong
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  className={classes.removeAcceptedButton}
                  onClick={handleMatchesLocal}
                  color="secondary"
                >
                  Matches
                </Button>
              </>
            )}
            {productMatch.type === "matched" && (
              <Button
                variant="contained"
                size="small"
                className={classes.removeAcceptedButton}
                onClick={handleNotAMatchLocal}
                color="primary"
              >
                Not A Match
              </Button>
            )}
            {productMatch.type === "wrong" && (
              <>
                {/* <Button
                  variant="contained"
                  size="small"
                  className={classes.removeAcceptedButton}
                  onClick={handleNotAMatchLocal}
                  color="secondary"
                >
                  Undo
                </Button> */}
                <Button
                  variant="contained"
                  size="small"
                  className={classes.removeAcceptedButton}
                  onClick={handleMatchesLocal}
                  color="secondary"
                >
                  Matches
                </Button>
                {productMatch.product_matching_group_id && (
                  <Chip
                    variant="outlined"
                    size="small"
                    icon={<WarningIcon />}
                    label={`Belonged to #${productMatch.product_matching_group_id}`}
                    color="secondary"
                    component="a"
                    href={`/matching-group/${productMatch.product_matching_group_id}`}
                    clickable
                  />
                )}
              </>
            )}
            {(productMatch.type === "potential"||productMatch.type === "variety") &&(
               <Button
                  variant="contained"
                  size="small"
                  className={classes.moreButtons}
                  onClick={handleMoreButtonsClick}    
                  // aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"              
                >
                  ...
                </Button>
            )}
            {showType === "original" && locked && (
              <>
                <Chip
                  icon={<AccountCircle />}
                  label={`${locked.firstName} on this product`}
                  className={
                    locked.byYou ? classes.lockedByYou : classes.lockedByOther
                  }
                  color="secondary"
                />
                {/* <Link
                  to={`/matching/pbs/wrongs/${productMatch.id}`}
                  className={classes.wrongListLink}
                  target="_blank"
                >
                  [Wrong list]
                </Link> */}
              </>
            )}
          </CardActions>
        </Card>
      </Grid>
  );
}
